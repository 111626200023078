// // MODULOS.VUE

<template>
  <v-layout id="modulos-permisos" wrap>
    <v-flex xs12>
      <v-card class="listado-modulos" v-bind="ent_modulos">
        <v-card-title class="header"
          ><v-icon>{{ ent_headerModulos.icon_header }}</v-icon>
          {{ ent_headerModulos.titulo }}
        </v-card-title>

        <v-card-text class="listado">
          <v-treeview
            v-bind="ent_treeview"
            :items="itemsMenu"
            :active.sync="moduloSeleccionado"
            return-object
          >
            <template v-slot:prepend="{ item }">
              <v-icon>{{
                item.activo === "1"
                  ? ent_treeview.icon_moduloActive
                  : ent_treeview.icon_moduloInactive
              }}</v-icon>
            </template>
          </v-treeview>
        </v-card-text>

        <v-card-actions class="btra">
          <!-- <v-btn v-bind="ent_btra" @click="creo_Modulo"><v-icon>{{ ent_btra.icon_crearMod }}</v-icon></v-btn>
                    <v-btn v-bind="ent_btra" @click="edito_Modulo"><v-icon>{{ ent_btra.icon_editMod }}</v-icon></v-btn> -->
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ["itemsMenu", "cfg"],

  data() {
    return {
      // variables de configuración
      ent_modulos: this.cfg,
      ent_headerModulos: this.cfg.header,
      ent_treeview: this.cfg.treeview,
      //ent_btra:this.cfg.btra,

      // variables del componente
      moduloSeleccionado: [] // módulo seleccionado (solo en MODO CLONACIÓN)
    };
  },

  watch: {
    moduloSeleccionado(modulo) {     
      console.log('modulo seleccionado: ', modulo);
      if (modulo[0] !== undefined)
        this.$emit("update_moduloSeleccionado", modulo);
    }
  }
};
</script>

<style></style>

<style scoped>
#modulos-permisos .listado-modulos .header i {
  padding-right: 10px;
}
</style>
